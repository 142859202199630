$text-color: #4a4a4a;
$primary: #b0779a;
$secondary: #81837f;
$tertiary: #fbebed;
$white: #fff;
$nav-color: #f1f5fa;
$black: black;
$dark: #777777;

$textColor2: #232323;
$textColor3: #a5a5a5;
$textColor4: #2b2b2b;
$textColor5: #2f2c2c;
$textColor6: #aaa;
$textColor7: #8c9094;
$textColor8: #bfbfbf;
$textColor9: #cccccc;

$backgroundColor1: #f4f7fb;

$border: #e8e8e8;
