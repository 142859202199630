@import "../../../../theme/base/_colors.scss";

.Dashboard-SideContent {
  position: fixed;
  padding-left: 3rem;
  width: 21.5vw;
  right: 0.8rem;
  @media screen and (max-width: 75em) {
    right: 0.3rem;
  }
  // Dashboard-SideContent-DoctorInfo starts here
  .Dashboard-SideContent-DoctorInfo {
    background: $tertiary 0% 0% no-repeat padding-box;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
    text-align: center;
    //margin-bottom: 3.5rem;
    .DoctorInfo-Icons {
      display: flex;
      justify-content: flex-end;
      padding: 2.5rem 1.4rem 1rem 0;
      .ant-badge-dot {
        width: 1.15rem;
        height: 1.15rem;
      }
      .anticon {
        font-size: 3rem;
        color: #d7bbcc;
        cursor: pointer;
      }
    }
    .DoctorInfo-Details {
      cursor: pointer;
      .NoProfileImg {
        background-color: $primary;
        .ant-avatar-string {
          font-size: 7rem;
          bottom: 0%;
        }
      }
    }
    h1 {
      font-size:1.8rem;
      font-weight: 600;
      color: $primary;
      margin-bottom: 0.3rem;
      padding-top: 1.5rem;
      text-transform: capitalize;
    }
    p {
      font-size: 1.8rem;
      font-weight: 500;
      letter-spacing: 0.99px;
      color: #B0779A;
      padding-bottom: 2.5rem;
    }
  }
  // Dashboard-SideContent-DoctorInfo ends here
}
