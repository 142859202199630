@import "../../theme/base/_colors.scss";

.Dashboard-container {
  .Dashboard-Sider-container {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    align-content: space-between;
    align-items: start;
    height: 100vh;
    padding-top: 3%;
    .sidebar-logo-image {
      padding-left: 1.7rem;
    }
    .sidebar-menu-items-container {
      margin-top: 2rem;
      display: grid;
      align-content: center;
      align-items: center;
      align-self: center;
      .sidebar-menu {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
      }
    }
    .logout-conatiner {
      padding-left: 2.5rem;
      align-self: end;
    }

    .sidebar-menu-conatiner {
    }
  }
  font-family: "Poppins", sans-serif;
  .ant-menu-dark,
  .ant-menu-dark .ant-menu-sub {
    color: rgba(255, 255, 255, 0.65);
  }
  .ant-menu.ant-menu-dark .ant-menu-item-selected,
  .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
    background-color: $white;
  }
  .ant-layout-content {
    background-color: $white;
  }
  .Dashboard-Ant-Content {
    margin-left: 20rem;
    @media only screen and (max-width: 75em) {
      margin-left: 22.2rem;
    }
  }
  .Dashboard-Ant-Content1 {
    margin-left: 9rem;
  }
  .Dashboard-Sider {
    position: fixed !important;
    z-index: 2;
  }
  .DashboardSider_Menu {
    display: flex;
    img {
      width: 2.4rem;
      height: 2.4rem;
    }
    span.Sider-Name {
      color: #232323;
      opacity: 0.5;
      font-size: 1.54rem;
      margin-top: -0.7rem;
      padding-left: 1rem;
      font-weight: 500;
      text-align: center;
      text-transform: capitalize;
    }
    span.Sider-Name1 {
      color: $primary;
      font-size: 1.55rem;
      margin-top: -0.7rem;
      padding-left: 1rem;
      font-weight: 600;
      text-align: center;
      text-transform: capitalize;
    }
    .ant-divider,
    .ant-divider-vertical {
      position: absolute;
      right: 0;
      width: 4px;
      height: 2.5rem;
      margin: 0;
      border-top-left-radius: 24px;
      border-bottom-left-radius: 24px;
      background-color: $primary;
    }
  }
  h4.Logout {
    font-size: 1.5rem;
    font-weight: 600;
    // position: fixed;
    // left: 2%;
    // bottom: 1%;
    color: #232323;
    opacity: 0.8;
    cursor: pointer;
    z-index: 2000;
  }
  li.ant-menu-item {
    display: flex;
    flex-direction: column;
    margin-top: 0;
    padding: 0 0 16% 2.2rem !important;
    height: auto;
    margin-bottom: 0 !important;
    background-color: $tertiary;
    color: $primary;
    span {
      color: $black;
      font-weight: 600;
      font-size: 1.2rem;
    }
    &:hover {
      background-color: $tertiary;
    }
  }
  .ant-menu-dark,
  .ant-menu-dark .ant-menu-sub {
    color: rgba(255, 255, 255, 0.65);
    background: $white;
  }
  .Dashboard-container .ant-menu-dark.ant-menu-inline,
  .Dashboard-container .ant-menu-dark.ant-menu-vertical,
  .Dashboard-container .ant-menu-dark.ant-menu-vertical-left,
  .Dashboard-container .ant-menu-dark.ant-menu-vertical-right {
    background: $white;
    margin-top: 100px;
  }

  img.Header_Logo {
    // // width: 160px;
    // cursor: pointer;
    // // position: fixed;
    // // top: 3%;
    // // left: 1%;
    // z-index: 1000;
    // display: block;
    cursor: pointer;
    z-index: 1000;
    display: block;
    width: 14rem;
    height: 4rem;
  }
  img.header_icon {
    // // width: 50px;
    // cursor: pointer;
    // // position: fixed;
    // // top: 3%;
    // // left: 1%;
    // z-index: 1000;
    // display: block;

    cursor: pointer;
    z-index: 1000;
    display: block;
    width: 6rem;
    height: 4rem;
  }

  .ant-menu.ant-menu-dark .ant-menu-item-selected,
  .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
    background-color: $tertiary;
  }

  section.ant-layout.ant-layout-has-sider {
    min-height: 100vh;
    aside#side_bar {
      position: relative;
      min-width: 0;
      background: $tertiary;
      transition: all 0.2s;
      width: 100% !important;
      min-width: auto !important;
    }
  }

  .ant-layout-sider-children {
    height: 100%;
    margin-top: -0.1px;
    padding-top: 0.1px;
    width: auto;
    background: $tertiary;
  }

  .ant-menu-inline {
    width: 100%;
    @media only screen and (min-width: 112.5em) {
      width: 240px;
    }
  }
  .ant-menu-inline-collapsed {
    width: 80px !important;
  }

  .ant-layout {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: auto;
    flex: auto;
    -ms-flex-direction: column;
    flex-direction: column;
    min-height: 0;
    background: $tertiary;
    overflow: hidden !important;
  }

  .ant-menu-dark.ant-menu-inline,
  .ant-menu-dark.ant-menu-vertical,
  .ant-menu-dark.ant-menu-vertical-left,
  .ant-menu-dark.ant-menu-vertical-right {
    border-right: 0;
    // margin-top: 4rem;
  }
}

ul.ant-menu.ant-menu-dark.ant-menu-inline-collapsed.ant-menu-root.ant-menu-vertical {
  li.ant-menu-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30% 32px !important;
    justify-content: center;
    height: auto;
    background-color: $tertiary;
    color: $primary;
    @media screen and (max-width: 96em) and (min-width: 75em) {
      padding: 22% 32px !important;
    }
  }
}

@media screen and (max-width: 90em) and (min-width: 70em) {
  .Dashboard-container {
    .ant-layout-sider-children {
      li.ant-menu-item {
        padding-bottom: 2.6rem !important;
      }
    }
  }
}
