@import "../../../../theme/base/_colors.scss";

.DashboardSettings-container {
  .DashboardSettings-body {
    position: relative;
    margin: 1.5% 1.5% 0 2%;
    h3{
      color: $primary;
      font-weight: 600;
      font-size: 1.8rem;
    
      cursor: pointer;
    }
    .Privacy-Container {
      overflow: hidden;
      .Privacy-Body-Content {
        margin-right: 4rem;
        .Privacy-Body-Edit {
          width: 96%;
          h3 {
            font-weight: 600;
            margin-bottom: 0;
          }
          .Privacy-Edit {
            color: $primary;
            position: relative;
            text-align: right;
            bottom: 2.4rem;
          }
          .Privacy-Switch {
            position: relative;
            text-align: right;
            bottom: 2.4rem;
            margin-bottom: 0;
            opacity: 1;
            .ant-switch-checked {
              background-color: black;
            }
          }
          .Privacy-Switch-two {
            position: relative;
            text-align: right;
            bottom: 2.4rem;
            margin-bottom: 0;
            opacity: 1;
            margin-top: unset;
            .ant-switch-checked {
              background-color: black;
            }
          }
          .Privacy-Content {
            color: #000;
            opacity: 0.5;
            margin-top: unset;
            font-size: 1em;
          }
          .Privacy-Content-two {
            color: #000;
            opacity: 0.5;
            margin-top: unset;
            font-size: 1.4rem;
            margin-top: 1rem;
            line-height: 2;
          }
          .Privacy-divider {
            margin: 2rem 0 2.6rem;
          }
        }
        .Privacy-Share {
          margin-top: 4rem;
          .ant-card-bordered {
            border: 2px solid #e8e8e8;
            border-radius: 10px;
          }
          .ant-card-body {
            padding: 3.4rem 2.6rem 5rem;
          }
          img {
            width: 3rem;
          }
          p {
            font-weight: 600;
            font-size: 1.7rem;
            opacity: 0.6;
            margin-top: 1.2rem;
            margin-bottom: 3.6rem;
          }
          .Privacy-Share-Content {
            font-weight: 600;
            color: #81837f;
            font-size: 1.2rem;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 56.25em) {
  .Privacy-Container {
    .anticon.anticon-left {
      display: none;
    }
  }
}
@media only screen and (max-width: 56.25em) and (min-width: 600px) {
  .Privacy-Container {
    overflow: hidden;
    .DashboardPrivacy-Body-Content {
      .Privacy-Body-Row {
        .ant-row {
          position: relative;
          height: auto;
          margin-right: 0;
          margin-left: 0;
          zoom: 1;
          display: flex;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          flex-direction: column-reverse;
        }
      }
      margin: 2rem;
      p:nth-child(1) {
        display: none;
      }
      .Privacy-Body-Edit {
        width: 95%;
        padding-left: 10%;
        padding-right: 0%;
        h3 {
          font-weight: 600;
          margin-bottom: 0;
          font-size: 3.2rem;
          width: 85%;
        }
        .Privacy-Edit {
          color: $primary;
          position: relative;
          text-align: right;
          bottom: 2.4rem;
          margin-left: 11%;
        }
        .Privacy-Switch {
          position: relative;
          text-align: right;
          bottom: 2.4rem;
          margin-bottom: 0;
          opacity: 1;
          .ant-switch-checked {
            background-color: black;
          }
        }
        .ant-switch {
          width: 10rem;
          height: 5rem;
          .ant-switch:not(.ant-switch-disabled):active::before,
          .ant-switch:not(.ant-switch-disabled):active::after {
            width: 30px;
          }
          &:after {
            -webkit-box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
            box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
            width: 24px;
            height: 24px;
            margin-top: 5px;
          }
        }
        .Privacy-Switch-two {
          position: relative;
          text-align: right;
          bottom: 2.4rem;
          margin-bottom: 0;
          opacity: 1;
          margin-top: -2.3rem;
          .ant-switch-checked {
            background-color: black;
          }
        }
        .Privacy-Content {
          color: #000;
          opacity: 0.5;
          margin-top: unset;
          font-size: 2.4rem;
          line-height: 2;
        }

        .Privacy-Content-two {
          color: #000;
          opacity: 0.5;
          margin-top: unset;
          font-size: 2.4rem;
          margin-top: 1rem;
          line-height: 2;
        }
        .Privacy-divider {
          margin: 2rem 0 2.6rem;
        }
      }

      .Privacy-Share {
        margin-top: 4rem;
        margin-bottom: 4.5rem;
        .ant-card-bordered {
          width: 100%;
          background: #ffffff 0% 0% no-repeat padding-box;
          box-shadow: 0px 10px 40px #71717129;
          border: 1px solid #ffffff;
          border-radius: 15px;
          opacity: 1;
          padding: 8% 2%;
          padding-bottom: 12%;
          margin-left: 6%;
          margin-bottom: 8%;
          position: relative;
          left: 24%;
        }
        .ant-card-body {
          padding: 0rem;
          padding-left: 2rem;
          padding-top: 1rem;
        }
        img {
          width: 6rem;
        }
        p {
          font-weight: 600;
          font-size: 3.4rem;
          opacity: 0.6;
          margin-top: 1.2rem;
          margin-bottom: 1rem;
        }
        .Privacy-Share-Content {
          font-weight: 600;
          color: #81837f;
          font-size: 2rem;
        }
      }
    }
  }
}

@media only screen and (max-width: 37.5em) {
  .Privacy-Container {
    overflow: hidden;
    .Privacy-Body-Content {
      .Privacy-Body-Row {
        .ant-row {
          position: relative;
          height: auto;
          margin-right: 0;
          margin-left: 0;
          zoom: 1;
          display: flex;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          flex-direction: column-reverse;
        }
      }
      margin: 2rem;
      p:nth-child(1) {
        display: none;
      }
      .Privacy-Body-Edit {
        width: 98%;
        padding-left: 5%;
        padding-right: 0%;
        h3 {
          font-weight: 600;
          margin-bottom: 0;
          font-size: 1.8rem;
          width: 85%;
        }
        .Privacy-Edit {
          color: $primary;
          position: relative;
          text-align: right;
          bottom: 2.4rem;
          margin-left: 11%;
        }
        .Privacy-Switch {
          position: relative;
          text-align: right;
          bottom: 2.4rem;
          margin-bottom: 0;
          opacity: 1;
          .ant-switch-checked {
            background-color: black;
          }
        }

        .Privacy-Switch-two {
          position: relative;
          text-align: right;
          bottom: 2.4rem;
          margin-bottom: 0;
          opacity: 1;
          margin-top: -2.3rem;
          .ant-switch-checked {
            background-color: black;
          }
        }
        .Privacy-Content {
          color: #000;
          opacity: 0.5;
          margin-top: unset;
          font-size: 1.4rem;
          line-height: 2;
        }

        .Privacy-Content-two {
          color: #000;
          opacity: 0.5;
          margin-top: unset;
          font-size: 1.4rem;
          margin-top: 1rem;
          line-height: 2;
        }
        .Privacy-divider {
          margin: 2rem 0 2.6rem;
        }
      }

      .Privacy-Share {
        margin-top: 4rem;
        margin-bottom: 4.5rem;
        .ant-card-bordered {
          width: 90%;
          background: #ffffff 0% 0% no-repeat padding-box;
          box-shadow: 0px 10px 40px #71717129;
          border: 1px solid #ffffff;
          border-radius: 15px;
          opacity: 1;
          padding: 2% 2%;
          margin-left: 6%;
          margin-bottom: 8%;
        }
        .ant-card-body {
          padding: 0rem;
          padding-left: 2rem;
          padding-top: 1rem;
        }
        img {
          width: 3rem;
        }
        p {
          font-weight: 600;
          font-size: 1.7rem;
          opacity: 0.6;
          margin-top: 1.2rem;
          margin-bottom: 1rem;
        }
        .Privacy-Share-Content {
          font-weight: 600;
          color: #81837f;
          font-size: 1.2rem;
        }
      }
    }
  }
}
