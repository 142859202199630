@import "../../../theme/base/_colors.scss";

.DashboardPayments-Body {
  position: relative;
  margin: 0 2.5% 0 2.5%;
  h3 {
    color: $primary;
    font-weight: 600;
    font-size: 1.8rem;
    padding-top: 2.05%;
    padding-bottom: 1.9%;
  }
  .DashboardPayments-ProfileInfo {
    position: absolute;
    right: 0;
    top: 0;
    //width: 30rem;
    background: $tertiary 0% 0% no-repeat padding-box;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    padding: 1.5% 4%;
    h3 {
      color: $primary;
      font-weight: 600;
      font-size: 1.8rem;
      padding: 0;
      margin-bottom: 0;
      text-transform: capitalize;
      cursor: pointer;
    }
    .ant-badge-dot {
      width: 1.15rem;
      height: 1.15rem;
    }
    .anticon {
      font-size: 3rem;
      color: #d7bbcc;
      cursor: pointer;
    }
  }
  .DashboardPayments-Filters {
    display: flex;
    justify-content: space-between;
    padding-bottom: 2%;
    .ant-select-selection {
      border: 1px solid #eaeaea;
      border-radius: 14px;
      height: 4rem;
      width: 17rem;
      &:hover {
        border-color: #eaeaea;
      }
    }
    .ant-select-selection-selected-value {
      color: $secondary;
      font-size: 1.5rem;
      font-weight: 500;
      padding-top: 0.4rem;
    }
    .DEFAULT {
      display: flex;
      justify-content: flex-end;
      padding: 0.8rem 1rem 0.5rem 0;
      cursor: pointer;
      img {
        width: 2.3rem;
        height: 2rem;
        margin-top: 0.2rem;
      }
      p {
        color: #575757;
        font-weight: 500;
        font-size: 1.55rem;
        padding-left: 1.6rem;
      }
    }
  }

  .DashboardPayments-Body-Table {
    margin-top: 3rem;
    .ant-spin-spinning {
      .ant-spin-dot-item {
        background-color: $primary;
      }
    }
    .ant-table-thead > tr:first-child > th:first-child {
      border-top-left-radius: 8px;
    }
    .ant-table-thead > tr > th {
      background: $primary 0% 0% no-repeat padding-box;
      color: $white;
      font-size: 1.5rem;
      font-weight: 500;
      padding-bottom: 1.5rem;
      text-transform: capitalize;
      text-align: center;
    }
    .ant-table-thead > tr:first-child > th:last-child {
      border-top-right-radius: 8px;
    }
    .ant-table-tbody > tr > td {
      font-size: 1.4rem;
      font-weight: 400;
      padding: 10px 12px;
      color: #333333;
      //height: 5.8rem;
      text-transform: capitalize;
    }
    .ant-table-tbody > tr > td {
      border-bottom: 1px solid $primary;
      text-align: center;
      .icons_info{
        display: flex;
        column-gap: 1rem;
        align-items: center;
        .anticon-dollar{
          color: $primary;
        }
      }
    }
    .ant-table-thead > tr > th:first-child,
    .ant-table-tbody > tr > td:first-child {
      padding-left: 3%;
    }
    .ant-table-thead > tr > th:last-child,
    .ant-table-tbody > tr > td:last-child {
      width: 1%;
      padding: 0 3rem 0 0;
    }
    .ant-table-tbody > tr:last-child > td,
    .ant-table-thead > tr > th {
      border-bottom: none;
    }
    .ant-table-body {
      border-radius: 8px;
      background: $tertiary 0% 0% no-repeat padding-box;
    }
    .ant-table-placeholder{
      border-top: none;
      border-radius: 20px;
    }
    .ant-table-tbody
      > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
      > td {
      background: transparent;
    }
  }
}

.anticon.EyeIcon {
  font-size: 1.5rem;
  color: $primary;
  cursor: pointer;
}

// Table pagination starts here
.ant-table-pagination.ant-pagination {
  float: unset !important;
  text-align: center;
  .ant-pagination-item-active {
    background: $primary;
    border-color: $primary;
    border-radius: 50%;
  }
  .ant-pagination-item a {
    color: #222222;
    padding-top: 1px;
    padding-bottom: 1px;
    &:hover {
      color: $primary;
    }
  }
  .ant-pagination-item-active a {
    color: $white;
    &:hover {
      color: $white;
    }
  }
  .ant-pagination-prev {
    margin-right: 1rem !important;
  }
  .ant-pagination-next {
    margin-left: 1rem !important;
  }
  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    background: $white !important;
    border-radius: 50%;
    box-shadow: 0 0.6px 2px 0.25px;
  }
  .ant-pagination-item:not(.ant-pagination-item-active) {
    border-radius: 50%;
  }
  .anticon {
    vertical-align: -1px;
    color: #bfbfbf;
  }
}
// Table pagination ends here

//payments view modal
.ant-modal.view_payments{
  width: 65% !important;
  .ant-modal-content{
    border-radius: 8px;
  }
  .ant-modal-close-x {
    display: block;
    color: #B0779A;
    width: 30px;
    height: 30px;
    font-size: 18px;
    line-height: 32px;
    border-radius: 50%;
    margin: 2rem;
  }
}
.ant-modal.view_updateModal{
  width: 45% !important;
  .ant-modal-body{
    height: 23rem;
  }
  .paymentupdate_container{
    margin: 2%;
    h3{
      font-size: 1.6rem;
      font-weight: 500;
      color: $primary;
    }
    .viewupdate_content{
      h4{
        text-transform: capitalize;
        font-size: 1.5rem;
        font-weight: 400;
        color: #333;
        margin-top: 2rem;
      }
      .ant-select{
        width: 40%;
        margin: 0 0%;
      }
      .update_btn{
        text-align: right;
        margin-top: 2rem;
        .ant-btn{
          background-color: $primary;
          border: none;
          color: #fff;
          font-size: 1.4rem;
        }
      }
    }
  }
}
