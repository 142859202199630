@import "../../../theme/base/_colors.scss";

.DashboardAppointments-Body {
  //margin: 0 1% 0 2%;

  .DashboardAppointments-Body-Content {
    position: relative;
    margin: 0 2.5% 0 2.5%;
    .DashboardAppointments-Tabs {
      .UpcomingContent-Body {
        .DEFAULT {
          display: flex;
          justify-content: flex-end;
          padding: 0 1rem 0.15rem 0;
          img {
            width: 2.3rem;
            height: 2rem;
            margin-top: 0.2rem;
          }
          p {
            color: #575757;
            font-weight: 500;
            font-size: 1.55rem;
            padding-left: 1.6rem;
          }
        }
        .ant-spin-spinning {
          .ant-spin-dot-item {
            background-color: $primary;
          }
        }

        // DAshboard Appointment table starts here
        .UpcomingContent-Body-Table {
          .ant-table-placeholder {
            border-radius: 25px;
            border-top: 0;
          }
          .ant-table-thead > tr:first-child > th:first-child {
            border-top-left-radius: 8px;
          }
          .ant-table-thead > tr > th {
            background: $primary 0% 0% no-repeat padding-box;
            color: $white;
            font-size: 1.4rem;
            font-weight: 500;
            padding-bottom: 1.5rem;
          }
          .ant-table-thead > tr:first-child > th:last-child {
            border-top-right-radius: 8px;
          }
          .ant-table-thead > tr > th,
          .ant-table-tbody > tr > td {
            font-size: 1.4rem;
            font-weight: 400;
            text-align: center;
          }
          .ant-table-tbody > tr > td {
            font-size: 1.4rem;
            font-weight: 400;
            color: #333333;
            //background-color: #f3f8ff;
            padding: 10px 16px;
            &:last-child {
              .anticon {
                // padding-left: 3rem;
                font-size: 1.5rem;
              }
            }
            &:nth-child(1) {
              text-transform: capitalize;
            }
          }
          // @media screen and (max-width: 97em) {
          //   @mixin commonfont-size {
          //     font-size: 1.4rem;
          //   }
          //   .ant-table-thead > tr > th {
          //     @include commonfont-size();
          //   }
          //   .ant-table-tbody > tr > td {
          //     @include commonfont-size();
          //     padding-left: 12px;
          //     padding-right: 12px;
          //   }
          //   .ant-table-tbody > tr > td:nth-child(5) {
          //     @include commonfont-size();
          //     white-space: nowrap;
          //   }
          //   // .ant-table-tbody > tr > td:nth-child(1) {
          //   // }
          //   .ant-table-tbody > tr > td:last-child {
          //     .anticon {
          //       font-size: 1.7rem;
          //       padding-left: 1rem;
          //     }
          //   }
          // }
          .ant-table-tbody > tr > td {
            font-size: 1.4rem;
            border-bottom: 1px solid $primary;
          }
          .ant-table-tbody > tr:last-child > td,
          .ant-table-thead > tr > th {
            border-bottom: none;
          }
          .ant-table-body {
            border-radius: 8px;
            background: $tertiary 0% 0% no-repeat padding-box;
          }
          .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
            background: transparent;
          }
        }
        // DAshboard Appointment table ends here
      }

      .ant-tabs-bar {
        border-bottom: none;
      }
      .ant-tabs-nav {
        width: 84%;
      }
      .ant-tabs-nav .ant-tabs-tab {
        color: #81827e;
        font-size: 1.6rem;
        opacity: 0.5;
        font-weight: 500;
        padding-left: 0;
        //width: 25%;
      }
      .ant-tabs-nav .ant-tabs-tab-active {
        color: #81827e;
        opacity: 1;
      }
      .ant-tabs-nav .ant-tabs-tab:hover {
        color: #81827e;
        opacity: 1;
      }
      .ant-tabs-ink-bar {
        background-color: $primary;
        height: 0.5rem;
        width: 7rem !important;
        border-top-left-radius: 50px;
        border-top-right-radius: 50px;
      }
    }
    .DashboardAppointments_ProfileInfo {
      position: absolute;
      right: 0;
      top: 0;
      //width: 30rem;
      background: $tertiary 0% 0% no-repeat padding-box;
      border-bottom-left-radius: 24px;
      border-bottom-right-radius: 24px;
      // display: flex;
      // justify-content: space-between;
      // align-items: center;
      padding: 1.5% 4%;
      h3 {
        color: $primary;
        font-weight: 600;
        font-size: 1.8rem;
        padding: 0;
        margin-bottom: 0;
        text-transform: capitalize;
        cursor: pointer;
      }
      // p {
      //   font-size: 1.8rem;
      //   font-weight: 500;
      //   letter-spacing: 0.99px;
      //   color: #b0779a;
      //   padding-top: 1.5rem;
      //   text-align: center;
      //   margin-bottom: 0;
      // }
      .ant-badge-dot {
        width: 1.15rem;
        height: 1.15rem;
      }
      .anticon {
        font-size: 3rem;
        color: #d7bbcc;
        cursor: pointer;
      }
    }
    h3 {
      color: $primary;
      font-weight: 600;
      font-size: 1.8rem;
      padding-top: 3%;
    }
    // DashboardAppointments-Tabs starts here

    // DashboardAppointments-Tabs ends here
  }

  .Dashboard-SideContent {
    position: fixed;
    padding-left: 3rem;
    width: 21.5vw;
    right: 0.8rem;
    @media screen and (max-width: 75em) {
      right: 0.3rem;
    }
    // Dashboard-SideContent-DoctorInfo starts here
    .Dashboard-SideContent-DoctorInfo {
      background: $tertiary 0% 0% no-repeat padding-box;
      border-bottom-left-radius: 24px;
      border-bottom-right-radius: 24px;
      text-align: center;
      //margin-bottom: 3.5rem;
      .DoctorInfo-Icons {
        display: flex;
        justify-content: flex-end;
        padding: 1.5rem 1.4rem 1rem 0;
        .ant-badge-dot {
          width: 1.15rem;
          height: 1.15rem;
        }
        .anticon {
          font-size: 3rem;
          color: #d7bbcc;
        }
      }
      .NoProfileImg {
        background-color: $primary;
        .ant-avatar-string {
          font-size: 7rem;
          bottom: 4%;
        }
      }
      h1 {
        font-size: 1.8rem;
        font-weight: 600;
        color: $primary;
        //margin-bottom: -0.4rem;
        padding-top: 1.5rem;
      }
      p {
        font-size: 1.8rem;
        font-weight: 500;
        letter-spacing: 0.99px;
        color: #b0779a;
        padding-bottom: 2.5rem;
      }
    }
    // Dashboard-SideContent-DoctorInfo ends here
  }
}

.CheckIcon,
.ClockIcon {
  color: $primary !important;
  font-size: 2.2rem;
  cursor: pointer;
}
.CloseIcon {
  color: #ef6d6b !important;
  font-size: 2.2rem;
  cursor: pointer;
}
.InfoIcon {
  color: $primary !important;
  font-size: 2.2rem;
  cursor: pointer;
}
.VIEW {
  color: $primary;
  font-weight: 600;
  font-size: 1.6rem;
  text-decoration: underline;
  cursor: pointer;
  text-transform: lowercase;
  margin-bottom: 0.3rem;
}

// DashboardAppointments-ViewModal starts here
.DashboardAppointments-ViewModal {
  width: 102rem !important;
  .ant-modal-content {
    border-radius: 8px;
    // background-color: #f3f8ff;
    margin-top: 2rem;
    ::-webkit-scrollbar {
      width: 0.8rem;
    }
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px $primary;
      border-radius: 10rem;
    }
    ::-webkit-scrollbar-thumb {
      background-color: $primary;
      border-radius: 4px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background-color: $primary;
    }
    ::-webkit-scrollbar-button {
      width: 50px;
    }
  }
  .ant-modal-body {
    padding: 0;
    position: relative;
    max-height: 94vh;
    overflow: auto;
    background-color: #fff;
    border-radius: 8px;
    .IconClose {
      position: absolute;
      color: #b0779a !important;
      font-size: 3.4rem;
      right: 2.8rem;
      top: 2.2rem;
      cursor: pointer;
    }
    .AppointmentDetails_Container {
      padding: 4% 3% 2% 6%;
      h3 {
        font-size: 1.6rem;
        font-weight: 500;
        color: $primary;
        padding: 1% 0;
      }
      .viewAppointment_details {
        .view_booking_content {
          width: 100%;
          display: grid;
          grid-template-columns: 48% 48%;
          grid-column-gap: 4%;
          padding: 0 2% 0 0;
          .view_booking_info {
            display: grid;
            grid-template-columns: 35% 5% 60%;
            width: 100%;
            margin-bottom: 1.2rem;
            overflow-wrap: anywhere;
            // border: 3px solid black;

            h4 {
              font-size: 1.4rem;

              font-weight: 500;
              margin-bottom: 0;
              text-transform: capitalize;
            }

            span {
              margin-right: 5%;
            }

            h5 {
              font-size: 1.4rem;
              color: #000;
              opacity: 0.7;
              font-weight: 500;
              margin-bottom: 0;
              text-transform: capitalize;
            }
            a {
              font-size: 2.6rem;
              margin-left: 15px;
              color: #2d80b5;
              border: 3px solid black;
            }
            .email_text {
              text-transform: lowercase;
            }
          }
        }
      }
      .view_booking_info1 {
        display: unset;
        margin-bottom: 1rem;
        h5 {
          font-size: 1.4rem;
          color: #333;
          opacity: 0.7;
          font-weight: 500;
          margin-bottom: 0.5rem;
          text-transform: capitalize;
          margin-top: 2rem;
        }
        h4 {
          font-size: 1.4rem;
          font-weight: 500;
          margin-bottom: 0;
          text-transform: capitalize;
        }
      }

      .Basic_Questions {
        h4 {
          font-size: 1.4rem;
          color: #333;
          opacity: 0.8;
          font-weight: 500;
        }

        .Questions-Content,
        .Questions-Content1 {
          display: flex;
          align-items: baseline;
          // grid-template-columns: repeat(4, 20%);
          flex-wrap: wrap;
          align-items: baseline;
          padding-top: 0.2rem;
          padding-bottom: 2.5%;

          .anticon-up-cirlce {
            font-size: 0.5rem;
            color: #2d80b5;
          }

          h5 {
            padding-left: 0.8rem;
            font-size: 1.4rem;
            font-weight: 500;
            color: #333;
          }

          .anticon svg {
            color: #67696c;
            background-color: #67696c;
            border-radius: 50%;
            font-size: 1.1rem;
          }
          font-size: 1.1rem;

          h3 {
            padding-left: 1.3%;
            margin-right: 9.5%;
            font-size: 1.4rem;
          }
        }

        .questions-Content-data {
          display: grid;
          width: 100%;
          grid-template-columns: 20% 20% 20% 20%;

          h5 {
            span {
              margin-left: 1rem;
            }
          }
        }
      }
      .Upload-Body {
        .Upload-Body-Content {
          display: flex;
          padding-bottom: 1.5%;
          h3 {
            width: 30%;
          }
          .FileContent {
            display: flex;
            h3 {
              width: unset;
              padding-right: 2rem;
            }
            .Uploadedfile {
              display: flex;
              background: $white 0% 0% no-repeat padding-box;
              border: 1px solid #e5e5e5;
              border-radius: 30px;
              width: 24rem;
              height: 3.8rem;
              justify-content: center;
              align-items: center;
              .anticon {
                color: $primary;
                opacity: 0.8;
                font-size: 1.65rem;
                align-self: center;
                margin-top: 0;
                margin-right: 0.4rem;
              }
              p {
                color: #52525a;
                font-weight: 400;
                opacity: 1;
                margin-bottom: 0;
                align-self: center;
                text-transform: lowercase;
              }
            }
          }
        }
      }
      .Patient-Messages-Content {
        h3 {
          color: $primary;
          font-size: 1.7rem;
        }
        h3:nth-child(2) {
          background: $white 0% 0% no-repeat padding-box;
          border-radius: 14px;
          padding: 1.25% 1.25% 2.75%;
          line-height: 2.5;
          width: 46%;
          color: $black;
          opacity: 0.6;
          font-weight: 500;
          font-size: 1.5rem;
        }
      }
      .Appointment_MeetingCard {
        .MeetingCard_content {
          .Meetingcard-Btns {
            text-align: center;
            padding-top: 1rem;

            .ant-btn {
              width: 16rem;
              height: 3.5rem;
              color: #fff;
              font-size: 1.4rem;
              font-weight: 400;

              border-color: $primary;
              border-radius: 25px;
            }
            .startBtn {
              background-color: $primary;
              margin-right: 1.5rem;
            }
            .medicationBtn {
              color: $primary;
              //background: #4fb8b6;
            }
          }
        }
      }
    }
  }
}
// DashboardAppointments-ViewModal ends here

// Accepted_Modal starts here
.ant-modal.Accepted_Modal {
  width: 72rem !important;
  .ant-modal-content {
    border-radius: 18px;
  }
  .ant-modal-close {
    top: 7.5px;
    right: 10px;
    .anticon {
      font-size: 2.7rem;
    }
  }
  .ant-modal-body {
    h2 {
      color: $secondary;
      text-align: center;
      font-size: 2rem;
      padding: 3.25rem 0 2rem;
      text-align: center;
    }
    .Accepted-Btns {
      display: flex;
      justify-content: center;
      button.ant-btn {
        border-color: $primary;
        border-radius: 18px;
        height: 4.25rem;
        width: 13.5rem;
        margin-top: 2.25rem;
        margin-bottom: 3.5rem;
        &:hover {
          border-color: $primary;
        }
      }
      .RemoveBtn {
        background-color: $primary;
        font-weight: 300;
        margin-right: 7rem;
        &:hover {
          background-color: $primary;
        }
      }
      .CancelBtn {
        color: $primary;
        font-weight: 500;
        &:hover {
          color: $primary;
        }
      }
    }
  }
}
// Accepted_Modal ends here

// Table pagination starts here
.ant-table-pagination.ant-pagination {
  float: unset !important;
  text-align: center;
  .ant-pagination-item-active {
    background: $primary;
    border-color: $primary;
    border-radius: 50%;
  }
  .ant-pagination-item a {
    color: #222222;
    padding-top: 1px;
    padding-bottom: 1px;
    &:hover {
      color: $primary;
    }
  }
  .ant-pagination-item-active a {
    color: $white;
    &:hover {
      color: $white;
    }
  }
  .ant-pagination-prev {
    margin-right: 1rem !important;
  }
  .ant-pagination-next {
    margin-left: 1rem !important;
  }
  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    background: $white !important;
    border-radius: 50%;
    box-shadow: 0 0.6px 2px 0.25px;
  }
  .ant-pagination-item:not(.ant-pagination-item-active) {
    border-radius: 50%;
  }
  .anticon {
    vertical-align: -1px;
    color: #bfbfbf;
  }
}
// Table pagination ends here
