@import "../../../../theme/base/colors";
.Reviews_container {
    margin: 2.5% 2% 1% 2%;
    height: 100%;
    padding: 1%;
    .anticon {
        margin-right: 1rem;
    }
    h3 {
        color: #b0779a;
        font-weight: 600;
        font-size: 1.8rem;
        cursor: pointer;
    }
    .reviews_table {
        .ant-table {
            //border: 1px solid #b0779a;
            .ant-table-body {
                border: 1px solid #b0779a;
                border-radius: 8px;
                background: $tertiary 0% 0% no-repeat padding-box;
            }
            .ant-table-thead > tr > th {
                background-color: #b0779a;
                font-size: 1.5rem;
                font-weight: 500;
                color: #fff;
                // text-align: center;
            }
            .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
                background-color: $tertiary;
            }
           
            .ant-table-tbody > tr > td {
                font-size: 1.4rem;
                font-weight: 400;
                color: #333;
                text-transform: capitalize;
                //border-bottom: 1px solid #4fb8b666;
                // text-align: center;
                .ant-rate {
                    font-size: 1.4rem;
        
                    .ant-rate-star {
                        margin-right: 2px;
                    }
                }
                &:last-child {
                    .anticon {
                        color: #b0779a;
                    }
                }
                &:nth-child(3) {
                    text-transform: capitalize;
                }
                &:nth-child(4){
                    text-transform: lowercase;
                }
            }
        }
    }
   
}


.ant-modal.view_review_modal {
    width: 52% !important;
  height: 35rem; 
    .view_reviews_content {
        width: 100%;
        display: grid;
        grid-template-columns: 48% 48%;
        margin-top: 3rem;
        :nth-child(1) {
          text-transform: capitalize;
        }
  
      .view_reviews_info {
        display: grid;
        grid-template-columns: 35% 5% 60%;
       // width: 100%;
        margin-bottom: 1.2rem;
        overflow-wrap: anywhere;
        h4 {
          font-size: 1.4rem;
          font-weight: 500;
          margin-bottom: 0;
          text-transform: capitalize;
        }
  
        span {
          margin-right: 5%;
        }
  
        h5 {
          font-size: 1.4rem;
          color: #000;
          opacity: 0.7;
          font-weight: 500;
          margin-bottom: 0;
          text-transform: capitalize;
        }
        .email_text {
          text-transform: lowercase;
        }
      }
    }
  }
 .ant-modal-close-x {
    color: #b0779a;
}
  