// @import  './Scss/base/_colors';
.loaderSpinner {
  .loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    backdrop-filter: blur(1px);
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .loader {
    border: 6px solid #f3f3f3;
    border-top: 6px solid #3498db;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 1s linear infinite;
    position: relative;
    /* Add this line */
    // top: calc(50% - 40px); /* Add this line */
    // left: calc(50% - 40px); /* Add this line */
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}
.Common-spin_container {
  position: absolute;
  top: 45% !important;
  z-index: 1000;
  .Common_spin_icon {
    font-size: 8rem;
  }
}
.disableTheButton {
  opacity: 0.5;
  pointer-events: none;
}

#SessionExpiredModal {
  // display: none !important;
  .session-expired-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
  }

  .modal_content_block {
    background-color: #fff;
    padding: 20px 10px;
    border-radius: 5px;
    text-align: center;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  }

  .modal-modal_content_block h2 {
    font-size: 2rem;
    margin-bottom: 10px;
  }

  .modal-modal_content_block p {
    font-size: 1.4rem;
    margin-bottom: 20px;
  }

  .login-button {
    background-color: #2d80b5;
    color: white;
    padding: 8px 16px;
    border-radius: 4px;
    border: none;
    font-size: 16px;
    cursor: pointer;
  }

}

@media screen and (max-width:600px) {
  #SessionExpiredModal {
    .modal_content_block {
      padding: 2rem 1rem;
    }

    .modal_content_block h2 {
      font-size: 1.9rem;
      margin-bottom: 10px;
    }

    .modal-content p {
      font-size: 1.4rem;
      margin-bottom: 2rem;
    }

    .login-button {
      padding: 8px 16px;
      font-size: 1.4rem;
    }

  }
}

