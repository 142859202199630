@import "../../../theme/base/_colors.scss";

.Signup-Container {
  padding-top: 1%;
  overflow: hidden;
  .HeaderlogoContainer {
    padding-left: 15%;
    img {
      width: 21.2rem;
    }
  }
  .SignupLoader.ant-spin-spinning {
    display: inline-block;
    opacity: 1;
    text-align: center;
    position: absolute;
    top: 40%;
    left: 47%;
    color: #d7bbcc;
    z-index: 1000;
  }
  .ant-spin {
    .ant-spin-dot-item {
      background-color: $primary;
    }
  }

  .signup-modal-body {
    h1 {
      font-size: 3.2rem;
      color: $textColor2;
      text-align: center;
    }
    p {
      font-size: 1.6rem;
      color: $dark;
      text-align: center;
    }
    .signupmodal-Content {
      padding-left: 4%;
      .signup-flelds-inputs {
        padding-top: 1%;
        padding-right: 2%;
        .Fields-Inputs,
        .Fields-Inputs1,
        .Fields-Inputs2,
        .Fields-Inputs3,
        .Fields-Inputs4 {
          display: flex;
          justify-content: center;
          .InputCustom {
            width: 35%;
            // margin-top: 2rem;
            margin-bottom: 1rem;
            .ERROR2 {
              margin-top: 0rem !important;
            }
          }
          .Input-Details {
            width: 35%;
            position: relative;
            p{
             // display: none;
             margin-bottom: 0 !important;
            }
            .InputCustom {
              width: 100%;
            }
            .ant-calendar-picker {
              width: 100%;
            }
            .ant-calendar-picker-clear,
            .ant-calendar-picker-icon {
              display: none;
            }
            .ant-select {
              width: 92%;
              // margin-bottom: 1.8rem;
              .ant-select-selection {
                box-shadow: none;
              }
              .ant-select-selection:hover,
              .ant-select-selection:focus {
                border-color: $primary;
              }
              .ant-select-selection--single {
                height: 4.5rem;
                font-size: 1.3rem;
                //padding-bottom: 1rem;
              }
              .ant-select-selection--single .ant-select-selection__rendered {
                height: 4.5rem;
              }
              .ant-select-arrow{
                line-height: 2;
              }
              .ant-select-selection-selected-value {
                //padding: 0.7rem 0;
                line-height: 3.3;
              }
            }
            .ant-upload-list-item {
              display: none;
            }
            img {
              width: 2rem;
              height: 2rem;
              cursor: pointer;
            }
            .anticon-cloud-upload {
              font-size: 2.2rem;
              color: $primary;
              padding-top: 0.7rem;
              cursor: pointer;
            }
            .ant-upload-list-item {
              display: none;
            }
            .google-places-autocomplete__input {
              width: 92%;
              height: 4.5rem;
              padding: 4px 11px;
              color: rgba(0, 0, 0, 0.65);
              font-size: 1.3rem;
              //line-height: 1.5;
              // margin-bottom: 1rem;
              background-color: $white;
              background-image: none;
              border: 1px solid #d9d9d9;
              border-radius: 4px;
              outline: none;
              box-shadow: none;
              &:hover,
              &:focus {
                border-color: $primary;
              }
            }
            .PinIMG {
              //position: absolute;
              right: 10%;
              top: 0.8rem;
            }
            .google-places-autocomplete__suggestions-container {
              position: absolute;
              top: 4.5rem;
              width: 92%;
              background-color: $white;
              border-radius: 15px;
              box-shadow: 0 2px 20px #d9d9d9;
              z-index: 1000;
              .google-places-autocomplete__suggestion {
                padding: 0.4rem 1rem 0.4rem 1.5rem;
                cursor: pointer;
              }
              .google-places-autocomplete__suggestion:nth-child(1) {
                padding-top: 0.6rem;
              }
              .google-places-autocomplete__suggestion:nth-last-child(1) {
                padding-bottom: 0.6rem;
              }
              .google-places-autocomplete__suggestion:hover {
                background-color: $primary;
                color: $white;
                border-radius: 2px;
              }
            }
          }
          .ant-input-affix-wrapper .ant-input-suffix {
            top: 2.2rem;
            right: 10%;
            font-size: 1.8rem;
            .ant-input-password-icon {
              color: $primary;
            }
            .ant-spin-spinning {
              padding-top: 0.8rem;
            }
          }
          .ERROR {
           // display: none;
            color: #f44336;
            font-size: 1.1rem;
            text-align: left;
            // margin-top: -0.6rem;
            margin-bottom: 0.6rem;
          }
          .ERROR2 {
            margin-top: 0rem !important;
          }
          .NO-ERROR {
            margin-top: 0;
            margin-bottom: 1rem;
          }
        }
        .Fields-Inputs1,
        .Fields-Inputs2 {
          margin-bottom: 1rem;
          .InputCustom {
            margin-bottom: 0rem;
          }
        }
        .Fields-Inputs2 {
          margin-bottom: 1.5rem;
        }
        .Fields-Inputs3 {
          margin-bottom: 2.6rem;
          .PinIMG {
            position: absolute;
            right: 10%;
            top: 0.8rem;
          }
          .InputCustom {
            margin-bottom: 0rem;
          }
          .ERROR {
            margin-bottom: 0rem;
          }
        }

       

        input.ant-input {
          height: 4.5rem;
          font-size: 1.3rem;
          //padding-bottom: 1rem;
          width: 92%;
          position: relative;
          box-shadow: none;
          &:hover,
          &:focus {
            border-color: $primary;
          }
        }
        .Fields-Inputs4 {
          margin-bottom: 1.95rem;
        }
        .ant-select-contrycode {
          width: 99%;
          // padding-bottom: 2.5rem;
          .ant-input-group-addon {
            padding: 0rem;
            width: 7rem;
          }
          .ant-select {
            width: 7rem;
            padding: 0px;
            .ant-select-selection--single {
              height: 4.5rem;
              margin: 0px -3px;
              .ant-select-arrow {
                .ant-select-arrow-icon svg {
                  margin-left: -2rem;
                }
              }
            }
          }
        }
      }
      label.ant-radio-button-wrapper.ant-radio-button-wrapper {
        height: 4rem;
        width: 92%;
        display: flex;
        align-items: center;
        border: 1px solid #d9d9d9;
      }
      .signup-agree {
        margin: 2% 0;
        text-align: center;
        .signup-agree-black {
          color: $textColor2;
          font-size: 1.25rem;
        }
        .signup-agree-active {
          color: #00a9e0;
          font-size: 1.25rem;
        }
        .ant-radio-wrapper:hover .ant-radio,
        .ant-radio:hover .ant-radio-inner,
        .ant-radio-input:focus + .ant-radio-inner,
        .ant-radio-checked .ant-radio-inner {
          border-color: $primary;
        }
        .ant-radio-inner::after {
          background-color: $primary;
        }
      }

      .signup-agree-button {
        text-align: center;
        margin-right: 5%;
        button.ant-btn.signup-agree-button-setup {
          background-color: $primary;
          width: 18rem;
          height: 4.5rem;
          color: $white;
          border-radius: 25px;
          font-size: 1.6rem;
          font-weight: 500;
          cursor: pointer;
          margin-bottom: 1%;
          &:hover {
            background-color: $primary;
            border-color: $primary;
            box-shadow: unset;
          }
          &:active {
            background-color: $primary;
            border-color: $primary;
            box-shadow: unset;
          }
          &:focus {
            background-color: $primary;
            border-color: $primary;
            box-shadow: unset;
          }
        }
      }
      .sign-account-check {
        display: flex;
        justify-content: center;
        padding-right: 30%;
        padding-top: 2%;
        font-weight: 500;
        .active-color {
          color: $primary;
          cursor: pointer;
        }
      }
    }
  }
  .signup-img {
    position: relative;
    bottom: 4%;
    width: 100%;
    img {
      width: 85%;
    }
  }
}
@media only screen and (max-width: 75em) {
  .signup-modal-body {
    .signupmodal-Content {
      .signupmodal-info {
        font-size: 1.1rem;
        font-weight: 500;
      }
      .signup-flelds-inputs {
        input.ant-input {
          width: 90%;
          font-size: 1.3rem;
        }
      }
      .button-next {
        margin-right: 4rem;
      }
    }
  }
}

.ant-modal.Categorys-Modal {
  position: absolute;
  left: 22%;
  .ant-modal-content {
    border-radius: 25px;
    width: 48vw;
    padding-left: 4rem;
    padding-top: 3.5rem;
    padding-bottom: 6rem;
    margin-top: 50%;
    height: auto;
  }
  .ant-modal-footer {
    display: none;
  }
  .ant-modal-close-x {
    display: none;
  }
  .ant-modal-body {
    padding: 0px;
    .Categorysvisible-contetnt {
      margin-top: 2%;
      margin-left: 10%;
      h2 {
        color: #363d58;
        font-size: 2rem;
        font-weight: 500;
      }
      .ant-radio {
        position: relative;
        bottom: 3px;
      }
    }
  }
}

// .ant-modal.DayCareCentreModal {
//   width: 78rem !important;
//   .ant-modal-content {
//     border-radius: 15px;
//     margin-top: 2rem;
//   }
// }

.ant-modal.ConditionModal {
  width: 72rem !important;
  .ant-modal-content {
    border-radius: 15px;
  }
  .ant-modal-body {
    padding: 2.4rem 6rem;
    .ConditionModal-Content {
      .HeaderImage,
      .Agree {
        text-align: center;
      }
      img {
        width: 23rem;
      }
      h3 {
        text-align: center;
        color: $black;
        font-weight: 600;
        margin: 1rem 0;
      }
      p {
        color: $black;
        font-size: 1.2rem;
        font-weight: 500;
      }
      ::-webkit-scrollbar {
        width: 0.8rem;
        border: 1px solid $border;
        border-radius: 4px;
      }
      ::-webkit-scrollbar-thumb {
        background: $textColor8;
        border-radius: 4px;
      }
      .ConditionsContent {
        padding-left: 2.6rem;
        margin-bottom: 3rem;
        height: 26rem;
        overflow-y: scroll;
        .Conditions-SubContent {
          display: flex;
          p {
            margin-bottom: 0;
          }
          p:nth-child(2) {
            padding-left: 1.4rem;
            width: 94%;
          }
        }
      }
      .CheckboxContent {
        .ant-checkbox-inner {
          top: 0.25rem;
          width: 1.4rem;
          height: 1.4rem;
        }
        .ant-checkbox + span {
          color: $black;
          font-size: 1.2rem;
          font-weight: 500;
          padding-left: 1.4rem;
        }
        .ant-checkbox-checked::after {
          border: none;
        }
        .ant-checkbox-checked .ant-checkbox-inner {
          background-color: $primary;
          border-color: $primary;
        }
        .ant-checkbox-inner::after {
          background-color: $primary;
          width: 5px;
          height: 8px;
          top: 48%;
        }
      }
      .ant-checkbox-wrapper {
        display: flex !important;
      }
      .ant-checkbox-wrapper:hover .ant-checkbox,
      .ant-checkbox:hover .ant-checkbox-inner,
      .ant-checkbox-input:focus + .ant-checkbox-inner {
        border-color: $primary;
      }
      .AgreeBtn {
        background-color: $primary;
        border-color: $primary;
        border-radius: 16px;
        margin-top: 2rem;
        width: 8rem;
        height: 3.5rem;
        font-weight: 500;
        &:hover {
          background-color: $primary;
          border-color: $primary;
        }
        &:focus {
          background-color: $primary;
          border-color: $primary;
        }
      }
    }
  }
}
