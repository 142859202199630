@import "../../../theme/base/_colors.scss";

.DashboardPatientList-Body {
  position: relative;
  margin: 0 1.5% 0 2%;
  h3 {
    color: $primary;
    font-weight: 600;
    text-transform: capitalize;
    font-size: 1.8rem;
    padding-top: 2.05%;
    padding-bottom: 1.9%;
  }
  .DashboardPatientList-ProfileInfo {
    position: absolute;
    right: 0;
    top: 0;
    //width: 30rem;
    background: $tertiary 0% 0% no-repeat padding-box;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    padding: 1.5% 4%;
    h3 {
      color: $primary;
      font-weight: 600;
      font-size: 1.8rem;
      padding: 0;
      margin-bottom: 0;
      text-transform: capitalize;
      cursor: pointer;
    }
    .ant-badge-dot {
      width: 1.15rem;
      height: 1.15rem;
    }
    .anticon {
      font-size: 3rem;
      color: #d7bbcc;
      cursor: pointer;
    }
  }
  .DashboardPatientList-Filters {
    display: flex;
    justify-content: space-between;
    padding-bottom: 2%;
    .ant-select-selection {
      border: 1px solid #eaeaea;
      border-radius: 14px;
      height: 4rem;
      width: 17rem;
      &:hover {
        border-color: #eaeaea;
      }
    }
    .ant-select-selection-selected-value {
      color: $secondary;
      font-size: 1.5rem;
      font-weight: 500;
      padding-top: 0.4rem;
    }
    .DEFAULT {
      display: flex;
      justify-content: flex-end;
      padding: 0.8rem 1rem 0.5rem 0;
      cursor: pointer;
      img {
        width: 2.3rem;
        height: 2rem;
        margin-top: 0.2rem;
      }
      p {
        color: #575757;
        font-weight: 500;
        font-size: 1.55rem;
        padding-left: 1.6rem;
      }
    }
  }

  // .DashboardPatientList-Body-Table {
  //   margin-top: 3rem;
  //   .ant-spin-spinning {
  //     .ant-spin-dot-item {
  //       background-color: $primary;
  //     }
  //   }
  //   .ant-table-thead > tr:first-child > th:first-child {
  //     border-top-left-radius: 8px;
  //   }
  //   .ant-table-thead > tr > th {
  //     background: $primary 0% 0% no-repeat padding-box;
  //     color: $white;
  //     font-size: 1.4rem;
  //     font-weight: 500;
  //     padding-bottom: 1.5rem;
  //     text-transform: capitalize;
  //   }
  //   .ant-table-thead > tr:first-child > th:last-child {
  //     border-top-right-radius: 8px;
  //   }
  //   .ant-table-thead > tr > th,
  //   .ant-table-tbody > tr > td {
  //     text-align: center;
  //   }
  //   .ant-table-tbody > tr > td {
  //     font-size: 1.4rem;
  //     font-weight: 400;
  //     color: #333333;
  //     height: 7.8rem;
  //   }
  //   .ant-table-tbody > tr > td {
  //     border-bottom: 1px solid $primary;
  //   }
  //   .ant-table-tbody > tr:last-child > td,
  //   .ant-table-thead > tr > th {
  //     border-bottom: none;
  //   }
  //   .ant-table-body {
  //     border-radius: 8px;
  //     background: $tertiary 0% 0% no-repeat padding-box;
  //   }
  //   .ant-table-placeholder{
  //     border-top: none;
  //     border-radius: 20px;
  //   }
  //   .ant-table-tbody
  //     > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  //     > td {
  //     background: transparent;
  //   }
  // }
  .DashboardPatientList-Body-Table {
    margin-top: 3rem;
    .ant-spin-spinning {
      .ant-spin-dot-item {
        background-color: $primary;
      }
    }
    .ant-table-thead > tr:first-child > th:first-child {
      border-top-left-radius: 8px;
    }
    .ant-table-thead > tr > th {
      background: $primary 0% 0% no-repeat padding-box;
      color: $white;
      font-size: 1.4rem;
      font-weight: 500;
      padding-bottom: 1.5rem;
      text-transform: capitalize;
    }
    // .ant-table-thead > tr:first-child > th:last-child {
    //   border-top-right-radius: 8px;
    // }
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td {
      font-size: 1.4rem;
      font-weight: 500;
      text-align: center;
      text-transform: capitalize;
    }

    .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
      background-color: $tertiary;
    }
    .ant-table-tbody > tr > td {
      color: #333;
      font-size: 1.4rem;
      font-weight: 500;
      text-align: center;
      text-transform: capitalize;
    }
    .ant-table-tbody > tr > td:nth-child(5) {
      font-size: 1.4rem;
      font-weight: 500;

      text-transform: lowercase;
      // height: 7.8rem;
    }

    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td {
      border-bottom: 1px solid #4fb8b666;
    }

    .ant-table-tbody > tr:last-child > td {
      border-bottom: none;
      span {
        font-size: 1.4rem;
        font-weight: 400;
        //color: #333;
      }
      .TEXT1 {
        color: #b0779a;
        text-decoration: underline;
        cursor: pointer;
      }
    }
    .ant-table-body {
      border-radius: 8px;
      background: $tertiary 0% 0% no-repeat padding-box;
    }
    // .ant-table-placeholder {
    //   border-top: none;
    //   border-radius: 20px;
    // }
  }

  .PatientInfo-Container {
    .PatientInfo-Body {
      display: flex;
      border-radius: 22px;
      background-color: #fbebed;
      padding: 3rem;
      margin-top: 2.6rem;
      .PatientInfo-Body-Avatar {
        padding-right: 3rem;
        .ant-avatar {
          margin-top: 1rem;
          background-color: $primary;
          .ant-avatar-string {
            font-size: 7rem;
            text-transform: uppercase;
          }
        }
        .ChatIcon {
          width: 2.6rem;
          height: 2.3rem;
          margin-top: 2rem;
          margin-left: 4.3rem;
        }
      }
      .PatientInfo-Body-Details {
        width: 38%;
        .details {
          display: flex;
          align-items: center;

          h3 {
            color: $black;
            opacity: 0.6;
            font-size: 1.4rem;
            margin-bottom: 0.5rem;
            font-weight: 500;
          }
          h3 {
            width: 9rem;
          }
          h4 {
            font-size: 1.4rem;
            font-weight: 500;
            color: #333333;
            margin-top: 0.3rem;
            padding-left: 5%;
            text-transform: capitalize;
          }
          .email_text {
            text-transform: lowercase;
          }
        }
      }
      .ant-divider,
      .ant-divider-vertical {
        width: 0.3px;
        height: 12rem;
        background-color: #4fb8b6;
        margin-right: 5%;
      }
    }
    .PatientInfo-Filters {
      display: flex;
      justify-content: space-between;
      padding-top: 1.7%;
      h3 {
        padding: 0.2rem 0 0;
      }
      .DEFAULT {
        display: flex;
        justify-content: flex-end;
        padding: 0.8rem 1rem 0.5rem 0;
        cursor: pointer;
        img {
          width: 2.3rem;
          height: 2rem;
          margin-top: 0.2rem;
        }
        p {
          color: #575757;
          font-weight: 500;
          font-size: 1.55rem;
          padding-left: 1.6rem;
        }
      }
    }
    .PatientInfo-Body-Table {
      .ant-spin-spinning {
        .ant-spin-dot-item {
          background-color: $primary;
        }
      }
      .ant-table-thead > tr:first-child > th:first-child {
        border-top-left-radius: 8px;
      }
      .ant-table-thead > tr > th {
        background: $primary 0% 0% no-repeat padding-box;
        color: $white;
        font-size: 1.4rem;
        font-weight: 400;
        padding-bottom: 1.5rem;
      }
      .ant-table-thead > tr:first-child > th:last-child {
        border-top-right-radius: 8px;
      }
      .ant-table-thead > tr > th,
      .ant-table-tbody > tr > td {
        text-align: center;
      }
      .ant-table-tbody > tr > td {
        font-size: 1.4rem;
        font-weight: 400;
        color: #333333;
        height: 5.8rem;
        text-transform: capitalize;
      }
      .ant-table-tbody > tr > td {
        border-bottom: 1px solid $primary;
        p {
          font-size: 1.5rem;
          font-weight: 500;
        }
      }
      .ant-table-placeholder {
        border-radius: 20px;
        border-top: none;
      }
      .ant-table-tbody > tr:last-child > td,
      .ant-table-thead > tr > th {
        border-bottom: none;
      }
      .ant-table-body {
        border-radius: 8px;
        background: $tertiary 0% 0% no-repeat padding-box;
      }
      .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
        background: transparent;
      }
    }
  }
}

.VIEW {
  color: $primary;
  font-weight: 500;
  font-size: 1.5rem;
  text-decoration: underline;
  cursor: pointer;
  margin-bottom: 0.2rem;
}
.TEXT1 {
  color: $primary;
  text-decoration: underline;
  cursor: pointer;
}
.ChatIcon {
  width: 2.5rem;
  height: 2.1rem;
  cursor: pointer;
}

// PatientInfo_Modal starts here
.PatientInfo_Modal {
  width: 102rem !important;
  .ant-modal-content {
    border-radius: 8px;
    // background-color: #f3f8ff;
    margin-top: 2rem;
    ::-webkit-scrollbar {
      width: 0.8rem;
    }
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px $primary;
      border-radius: 10rem;
    }
    ::-webkit-scrollbar-thumb {
      background-color: $primary;
      border-radius: 4px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background-color: $primary;
    }
    ::-webkit-scrollbar-button {
      width: 50px;
    }
  }
  .ant-modal-body {
    padding: 0;
    position: relative;
    max-height: 94vh;
    overflow: auto;
    .IconClose {
      position: sticky;
      color: $primary;
      font-size: 3.5rem;
      float: right;
      right: 2rem;
      top: 2rem;
      cursor: pointer;
    }
    // Patient Details View starts here
    .Patient-DetailsView-Body {
      .AppointmentDetails_Container {
        padding: 4% 3% 2% 4%;

        h3 {
          font-size: 1.6rem;
          font-weight: 500;
          color: $primary;
          padding: 1% 0;
        }
        .viewAppointment_details {
          .view_booking_content {
            width: 100%;
            display: grid;
            grid-template-columns: 48% 48%;
            grid-column-gap: 4%;
            //padding: 0 2%;

            .view_booking_info {
              display: grid;
              grid-template-columns: 35% 5% 60%;
              width: 100%;
              margin-bottom: 1.2rem;
              overflow-wrap: anywhere;

              h5 {
                font-size: 1.4rem;
                opacity: 0.8;
                font-weight: 500;
                margin-bottom: 0;
                text-transform: capitalize;
              }

              span {
                margin-right: 5%;
              }

              h4 {
                font-size: 1.4rem;
                color: #333;

                font-weight: 500;
                margin-bottom: 0;
                text-transform: capitalize;
                span {
                  padding-left: 1%;
                }
              }
              a {
                font-size: 2.6rem;
                margin-left: 15px;
                color: $primary;
              }
              .email_text {
                text-transform: lowercase;
              }
            }
          }
        }
        .Basic_Questions {
          h4 {
            font-size: 1.4rem;
            color: #333;
            opacity: 0.8;
            font-weight: 500;
          }

          .Questions-Content,
          .Questions-Content1 {
            display: flex;
            align-items: baseline;
            // grid-template-columns: repeat(4, 20%);
            flex-wrap: wrap;
            align-items: baseline;
            padding-top: 0.2rem;
            padding-bottom: 2.5%;

            .anticon-up-cirlce {
              font-size: 0.5rem;
              color: #2d80b5;
            }

            h5 {
              padding-left: 0.8rem;
              font-size: 1.4rem;
              font-weight: 500;
              color: #333;
            }

            .anticon svg {
              color: #67696c;
              background-color: #67696c;
              border-radius: 50%;
              font-size: 1.1rem;
            }
            font-size: 1.1rem;

            h3 {
              padding-left: 1.3%;
              margin-right: 9.5%;
              font-size: 1.4rem;
            }
          }

          .questions-Content-data {
            display: grid;
            width: 100%;
            grid-template-columns: 20% 20% 20% 20%;

            h5 {
              span {
                margin-left: 1rem;
              }
            }
          }
        }

        // overflow-y: auto;

        .Details-Content {
          display: flex;
          padding-bottom: 3.5%;

          h3:nth-child(1) {
            width: 26%;
          }

          h3:nth-child(2) {
            padding-left: 6%;
            padding-right: 10%;
          }
        }

        .Upload-Body {
          .Upload-Body-Content {
            display: flex;
            padding-bottom: 1.5%;
            align-items: center;

            .FileContent {
              display: flex;

              .Uploadedfile {
                display: flex;
                background: var(--half-white) 0% 0% no-repeat padding-box;
                border: 1px solid var(--grey-seventh);
                border-radius: 30px;
                width: 24rem;
                height: 3.8rem;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                .anticon {
                  color: var(--primary);
                  opacity: 0.8;
                  font-size: var(--text-16);
                  align-self: center;
                  margin-top: 0;
                  margin-right: 0.4rem;
                }

                p {
                  color: var(--ash-twelve);
                  font-weight: 400;
                  opacity: 1;
                  margin-bottom: 0;
                  align-self: center;
                  text-transform: lowercase;
                  font-size: var(--text-14);
                }
              }
            }
          }
        }

        .Patient-Messages-Content {
          h3:nth-child(2) {
            background: var(--half-white) 0% 0% no-repeat padding-box;
            border-radius: 14px;
            padding: 1.25% 1.25% 2.75%;
            line-height: 2.5;
            opacity: 0.8;
            font-size: 1.4rem;
            font-weight: 500;
            color: #333;
          }
        }

        .Appointment_MeetingCard {
          .MeetingCard_content {
            .Meetingcard-Btns {
              text-align: center;
              padding-top: 1rem;

              .ant-btn {
                width: 16rem;
                height: 3.5rem;
                color: #fff;
                font-size: 1.4rem;
                font-weight: 400;

                border-color: $primary;
                border-radius: 25px;
              }
              .startBtn {
                background-color: $primary;
                margin-right: 1.5rem;
              }
              .medicationBtn {
                color: $primary;
                //background: #4fb8b6;
              }
            }
          }
        }
      }
    }
    // Patient Details View ends here

    // Patient MedicationView starts here
    .Patient-MedicationView-Body {
      .Patient-MedicationView-Body-Table {
        padding-top: 14.8rem;
        .ant-spin-spinning {
          .ant-spin-dot-item {
            background-color: $primary;
          }
        }
        .ant-table-thead > tr:first-child > th:first-child {
          border-top-left-radius: 0;
        }
        .ant-table-thead > tr > th {
          background: $primary 0% 0% no-repeat padding-box;
          color: $white;
          font-size: 1.65rem;
          font-weight: 400;
          padding-top: 1.2rem;
          padding-bottom: 1.2rem;
          text-transform: capitalize;
        }
        .ant-table-thead > tr:first-child > th:last-child {
          border-top-right-radius: 0;
        }
        .ant-table-thead > tr > th,
        .ant-table-tbody > tr > td {
          text-align: center;
        }
        .ant-table-tbody > tr > td {
          font-size: 1.7rem;
          font-weight: 500;
          color: #333434;
          text-transform: capitalize;
        }
        .ant-table-tbody > tr:first-child > td {
          padding-top: 3.9rem;
        }
        .ant-table-thead > tr > th,
        .ant-table-tbody > tr > td {
          border-bottom: none;
        }
        .ant-table-tbody > tr:last-child > td {
          border-bottom: none;
        }
        .ant-table-body {
          height: 64vh;
          border-radius: 25px;
          background: $tertiary 0% 0% no-repeat padding-box;
        }
        .ant-table-placeholder {
          position: absolute;
          top: 38%;
          right: 44%;
          border: none;
          background: none;
          border-top: none;
          border-radius: 20px;
        }
        .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
          background: transparent;
        }
      }
    }
    // Patient MedicationView ends here
  }
}
// PatientInfo_Modal ends here

// Table pagination starts here
.ant-table-pagination.ant-pagination {
  float: unset !important;
  text-align: center;
  .ant-pagination-item-active {
    background: $primary;
    border-color: $primary;
    border-radius: 50%;
  }
  .ant-pagination-item a {
    color: #222222;
    padding-top: 1px;
    padding-bottom: 1px;
    &:hover {
      color: $primary;
    }
  }
  .ant-pagination-item-active a {
    color: $white;
    &:hover {
      color: $white;
    }
  }
  .ant-pagination-prev {
    margin-right: 1rem !important;
  }
  .ant-pagination-next {
    margin-left: 1rem !important;
  }
  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    background: $white !important;
    border-radius: 50%;
    box-shadow: 0 0.6px 2px 0.25px;
  }
  .ant-pagination-item:not(.ant-pagination-item-active) {
    border-radius: 50%;
  }
  .anticon {
    vertical-align: -1px;
    color: #bfbfbf;
  }
}
// Table pagination ends here
