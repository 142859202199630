@import "../../../theme/base/_colors.scss";

.ServiceType-Body {
  overflow: hidden;
  .ServiceType-Body-Headerlogo {
    padding: 1.5% 0 2.5% 10.25%;
    img {
      width: 26rem;
    }
  }
  .ServiceType-Body-Heading {
    padding-top: 4%;
    text-align: center;
    width: 80%;
    h3 {
      color: $secondary;
      font-size: 1.7rem;
      text-transform: none;
      font-weight: 400;
    }
  }
  .ServiceType-Body-img {
    padding-left: 10%;
    padding-top: 2%;
    position: relative;
    bottom: 4%;
    width: 100%;
    img {
      width: 69%;
    }
  }
  .ServiceType-Body-dot-setup {
    display: flex;
    justify-content: center;
    padding-top: 7%;
    padding-left: 12%;
    position: relative;
    right: 24%;
    .disable-dot {
      margin-right: 0.5rem;
      height: 1rem;
      width: 1rem;
      border-radius: 50%;
      background-color: $primary;
    }
    .active-dot {
      margin-right: 0.5rem;
      height: 1rem;
      width: 3.5rem;
      background-color: $primary;
      border-radius: 25px;
    }
  }
  .ServiceType-Body-Question-setup {
    padding-top: 24%;
    padding-bottom: 16%;
    h1 {
      color: #363d58;
      font-size: 2.9rem;
    }
    .ant-checkbox-group {
      display: flex;
      flex-direction: column;
    }
    .ant-checkbox-wrapper + .ant-checkbox-wrapper {
      margin-left: 0;
      margin-top: 1rem;
    }
    .ant-checkbox + span {
      padding-left: 16px;
      color: #2f2c2c;
      font-size: 2.36rem;
    }
    .ant-checkbox-inner {
      width: 1.8rem;
      height: 1.8rem;
      margin-top: -0.6rem;
    }
    .ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner,
    .ant-checkbox-input:focus + .ant-checkbox-inner {
      border-color: $primary;
    }
    .ant-checkbox-checked::after {
      border: none;
    }
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: $primary;
      border-color: $primary;
    }
    .ant-checkbox-group-item {
      padding-bottom: 1.8rem;
    }
  }

  .ServiceType-Body-button {
    display: flex;
    justify-content: center;
    margin-bottom: 1%;
    align-items: center;
    padding-left: 20%;
    padding-top: 8%;
    img {
      width: 4.5rem;
      position: relative;
      right: 35%;
      cursor: pointer;
    }
  }
}
