@import "../../../../theme/base/_colors.scss";

.ResetPassword-container {
  padding-top: 1%;
  padding-left: 5%;
  overflow: hidden;
  .HeaderlogoContainer{
    img{
      width: 20rem;
    }
  }
  .Login-flelds-body {
    padding-top: 5%;
    h1 {
      color: $textColor2;
      line-height: 10px;
    }
    h3 {
      line-height: 1;
      color: $dark;
    }
  }
  .ant-spin-spinning {
    display: inline-block;
    opacity: 1;
    text-align: center;
    position: absolute;
    top: 45%;
    right: 4%;
    color: #d7bbcc;
    z-index: 1000;
  }
  .Login-flelds-inputs {
    padding-top: 30%;
    input.ant-input {
      height: 4.5rem;
      margin: 0.4rem 0 0.8rem;
      width: 82%;
      box-shadow: none;
      &:hover,
      &:focus {
        border-color: $primary;
      }
    }
    .ant-input-affix-wrapper {
      width: 82%;
      input.ant-input {
        width: 100%;
      }
      .ant-input-suffix {
        top: 47%;
        .ant-input-password-icon {
          font-size: 1.8rem;
          color: $primary;
        }
      }
    }
  }
  .Login-agree {
    line-height: 1;
    display: flex;
    justify-content: space-between;
    span {
      cursor: pointer;
      font-weight: 500;
    }
    span:nth-child(2) {
      padding-right: 18%;
    }
  }
  .Login-button {
    display: flex;
    justify-content: center;
    padding-top: 15%;
    padding-bottom: 10%;
    margin-right: 15%;
    cursor: pointer;
    img {
      width: 5rem;
      margin-right: 13%;
    }
  }
  .Login-account-check {
    text-align: center;
    margin-right: 25%;
    font-weight: 500;
    span:nth-child(2) {
      color: $primary;
    }
    .active-color {
      color: $primary;
      cursor: pointer;
    }
  }
  .Login-flelds-success {
    text-align: center;
    position: relative;
    right: 16%;
    padding-top: 18%;
    img {
      margin-left: 5%;
      width: 5rem;
      margin-top: 10%;
      margin-bottom: 10%;
      cursor: pointer;
    }
    .Login-password-links {
      h3 {
        font-size: 1.4rem;
        cursor: pointer;
      }
    }
    h3:nth-child(2) {
      padding-bottom: 25%;
      span {
        color: $primary;
      }
    }
  }
  .Login-password-check {
    h4 {
      cursor: pointer;
      padding-top: 3%;
    }
  }
  .Login-Forgot {
    // padding-top: 28%;
  }
  .Login-password-Reset {
    // padding-top: 20%;
    .ResetPassword-Click {
      display: flex;
      justify-content: center;
      padding-right: 20%;
      padding-top: 5%;
      font-weight: 500;
      .active-color {
        color: $primary;
        cursor: pointer;
      }
      img {
        width: 5rem;
        margin-bottom: 6rem;
        cursor: pointer;
      }
    }
  }
  .Loginpage-img {
    position: relative;
    bottom: 4%;
    width: 100%;
    img {
      width: 70%;
      padding-top: 5rem;
      margin-left: 5rem;
    }
  }
}
